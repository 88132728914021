import Amplify from 'aws-amplify';

export function AmplifyConf() {
    Amplify.configure({
        // CDK版
        //Auth: {
        //    identityPoolId: 'ap-northeast-1:5b5b385b-924b-4196-aa30-cc751aded5b6',
        //    region: 'ap-northeast-1',
        //    userPoolId: 'ap-northeast-1_HqNbIIdCq',
        //    userPoolWebClientId: '3bhra7h4dg16kor8p4v10u362k'
        //}
        // 仮作成版
        Auth: {
            identityPoolId: 'ap-northeast-1:45f9eb83-9d70-4e36-b31f-4969959466d5',
            region: 'ap-northeast-1',
            userPoolId: 'ap-northeast-1_6SkQgCAU7',
            userPoolWebClientId: '3npl5jetsbelg5op03ukc64fn9'
        }
    });
}
