import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/archive',
    name: 'Archive',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "archive" */ '../views/Archive.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
      name: 'notFound',
      path: '*',
      component: Home,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.name === 'Login') {
    if(store.getters.authStatus) {
      next();
    }
    else {
      next('/');
    }
  }
  if(to.name === 'About') {
    if(store.getters.authStatus) {
      next();
    }
    else {
      next('/');
    }
  }
  if(to.name === 'notFound') {
    next('/');   
  }
  if(to.name === 'Home') {
    if(store.getters.authStatus) {
      next('/Login');
    }
    else {
      next();
    }
  }
  next();
});

export default router
