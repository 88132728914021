import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cognitoAuthStorage: {},
    authStatus: false,
  },
  mutations: {
    onCognitoAuthStorageChanged(state, cognitoAuthStorage) {
      state.cognitoAuthStorage = cognitoAuthStorage;
    },
    onChangedAuthStatus(state, authStatus) {
      state.authStatus = authStatus;
    },
    refresh(state) {
      state.cognitoAuthStorage = {};
      state.authStatus = false;
    },
  },
  getters: {
    cognitoAuthStorage(state) {
      return state.cognitoAuthStorage;
    },
    authStatus(state) {
      return state.authStatus;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({storage: window.sessionStorage})]
});
