<template>
  <div class="home">
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="6"
            lg="6"
            md="6"
            sm="6"
          >
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="ログインID"
              :rules="rules"
              v-model="username"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="6"
            lg="6"
            md="6"
            sm="6"
          >
            <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-icon="mdi-lock"
              label="パスワード"
              :rules="rules"
              v-model="password"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card-actions>
              <v-btn 
                class="info"
                :disabled="!valid"
                @click="submit"
              >ログイン</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth } from 'aws-amplify';
import { AmplifyConf } from './../plugins/amplifyConf'

  export default {
    name: 'Home',
  
    data: () => ({
      username: "",
      password: "",
      showPassword : false,
      valid: false,
      rules: [
        value => !!value || '入力必須です'
      ],
    }),
    
    computed: {
      cognitoAuthStorage() {
        return this.$store.getters.cognitoAuthStorage;
      }
    },

    beforeCreate: () => {
      AmplifyConf();
    },

    methods: {
      submit() {
        const v_username = this.username;
        const v_password = this.password;
        const v_router = this.$router;
        const v_store = this.$store;

        Auth.signIn(v_username, v_password)
          .then((obj) => {
            //console.log('ログインに成功しました');
            v_store.commit('onCognitoAuthStorageChanged', obj.storage);
            v_store.commit('onChangedAuthStatus', true);
            v_router.push('/login');
          })
          .catch(() => {
            //console.log(obj);
            //console.log('ログインに失敗しました')
          })
      }
    },

  }
</script>
